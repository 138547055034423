(function() { 'use strict';
  // Const
  // -----
  window.MOBILE_WIDTH = 768;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  //window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);

  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
  });

  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < MOBILE_WIDTH;
  };


  // Masked input
  // ------------
  // Phone
  $('input[type="tel"]').inputmask('+7 (999) 999-99-99', {
    placeholder: "+7 (___) ___-__-__"
  });


  // Scrolling to top
  // ----------------
  if( IS_DESKTOP ) {
    var goTopBtn = $('#to_top > div');

    goTopBtn.click(function() {
      $WINDOW.scrollTo(0, 200);
    });

    $WINDOW.on('scroll', function(e, goTopBtnShowSpeed) {
      if( goTopBtnShowSpeed === undefined )
        goTopBtnShowSpeed = 200;

      if( $WINDOW.scrollTop() > 130 ) {
        goTopBtn.show(goTopBtnShowSpeed);
      } else {
        goTopBtn.hide(200);
      }
    }).trigger('scroll', [0]);
  }


  // FancyBox
  // --------
  $('.phone_order, .fancybox').fancybox({
    width: 400,
    height: 'auto',

    minWidth: 0,
    minHeight: 0,

    autoSize: false,

    fitToView: true,

    scrolling: 'no',

    closeBtn: true,
    closeClick: false,
    nextClick: false,
    modal: false,
    loop: false,

    openEffect: 'none',
    closeEffect: 'none',

    helpers: {
      overlay: {
        closeClick: true,
        speedOut: 0,
        showEarly: true,
        locked: true
      },
      title: {
        type: 'inside',
        position: 'top'
      }
    },

    beforeShow: function () {
      $.fancybox.wrap.bind('contextmenu', function(e) {
        return false;
      });
    },

    afterLoad: function () {
      $.fancybox.helpers.overlay.overlay.bind('contextmenu', function(e) {
        return false;
      });
    },

    afterShow: function () {
      $.fancybox.wrap.find('.js-autofocus-inp').focus().select();
    }
  });


  // Header social buttons
  // ---------------------
  $('header .social').on('click', 'a', function(e) {
    e.preventDefault();
    var socialWin;
    if( socialWin = window.open($(this).prop('href'), '_blank', 'toolbar=0,location=0,status=0,scrollbars=1,left=50,top=50,width=960,height=600'))
      socialWin.focus();
  });


  // Header menu
  // -----------
  // Header menu button
  $('.header-menu-checkbox')
    .on('change', function() {
      if( this.checked ) {
        $BODY.addClass('posr ofh');
      } else {
        $BODY.removeClass('posr ofh');
      }
    })
    .prop('checked', false)
    .prop('disabled', false)
    .trigger('change');

  $WINDOW.on('resize', function() {
    if( window.IS_MOBILE_WIDTH() ) {
      var $headerMenuContainer = $('.header-menu-container');

      if( !$headerMenuContainer.hasClass('with-menu') ) {
        $headerMenuContainer.addClass('with-menu');
        $('<div class="left_col"></div>').prepend($('#structure .content .left_col > .menu_ul_header, #structure .content .left_col > ul, header .top_menu').clone()).prependTo('header .header-menu-container .header-menu');
      }
    }
  });

  $DOCUMENT
    .on('click.header-menu', '.header-menu-container .header-menu .top_menu > li > a', function(e) {
      e.preventDefault();

      var $elem = $(this),
          $parent = $(this).closest('li'),

          isWithDropdown = $parent.children('ul').length > 0,
          isOnHover = $parent.hasClass('active') || $parent.hasClass('is-hovered');

      if( isWithDropdown ) {
        if( isOnHover ) {
          location.href = $elem.prop('href');
        } else {
          $parent.addClass('is-hovered').children('ul').slideDown(350);
        }
      } else {
        location.href = $elem.prop('href');
      }
    })

    .on('click.js-tabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this);

      if( !$lnk.hasClass('active') ) {
        var $destination = $($lnk.parents('.js-tabs').data('tabDest')),
            cssClass = $lnk.hasClass('btn') ? 'active orange' : 'active';

        $lnk.addClass(cssClass).siblings().removeClass(cssClass);
        $destination.removeClass('hidden').find('.js-tabs-content').children().addClass('hidden').eq($lnk.index()).removeClass('hidden');
      }
    });


  // Rotator multi slideshow
  // -----------------------
  $WINDOW.on('resize', function() {
    var $rotatorMultiSlideshow = $('.rotator-multi .cycle-slideshow'),
        rotatorMultiSlideshowOpts = {
          log: false,
          allowWrap: false,
          autoHeight: '1:1',
          slides: '> .cycle-slide-item',
          fx: 'carousel',
          carouselVisible: 3,
          pauseOnHover: true,
          speed: 500,
          timeout: 0,
          updateView: 0
        };

    if( window.IS_MOBILE_WIDTH() ) {
      rotatorMultiSlideshowOpts.carouselVisible = 1;

      if( !$rotatorMultiSlideshow.hasClass('cycle-slideshow-multi1') )
        $rotatorMultiSlideshow
          .on('cycle-destroyed', function() {
            $(this).removeClass('cycle-slideshow-initialized');
          })
          .on('cycle-initialized', function() {
            $(this).removeClass('cycle-slideshow-multi3').addClass('cycle-slideshow-initialized cycle-slideshow-multi1');
          });

        if( $rotatorMultiSlideshow.hasClass('cycle-slideshow-initialized') )
          $rotatorMultiSlideshow.cycle('destroy');

        $rotatorMultiSlideshow.cycle(rotatorMultiSlideshowOpts);
    } else {
      if( !$rotatorMultiSlideshow.hasClass('cycle-slideshow-multi3') )
        $rotatorMultiSlideshow
          .on('cycle-initialized', function() {
            $(this).removeClass('cycle-slideshow-multi1').addClass('cycle-slideshow-initialized cycle-slideshow-multi3');
          })
          .cycle('destroy')
          .cycle(rotatorMultiSlideshowOpts);
    }
  });


  // Callbacks
  // ---------
  CALLBACKS['reachGoalPhoneOrder'] = function(){
    if( typeof yaCounter29769213 === 'object' )
      yaCounter29769213.reachGoal('phone_order');
  };


  // Triggers
  // --------
  $WINDOW.trigger('resize');

})();